<template>
  <table border="1">
    <tr>
      <td class="bgColor">考试名称</td>
      <td colspan="4">{{ formList.examName }}</td>
    </tr>
    <tr>
      <td class="bgColor">考试时间</td>
      <td colspan>{{ formList.examStartTime | dayList }}</td>
      <td class="bgColor">设置试卷</td>
      <td colspan>{{ formList.examModel | examModelFilters }}</td>
    </tr>
    <tr>
      <!-- <td class="bgColor" :rowspan="formList.examArrangementPreviewSubjectInfoList.length + 1">考试科目</td> -->
      <td class="bgColor" rowspan="4">考试科目</td>
    </tr>
    <!-- <tr v-for="item in formList.examArrangementPreviewSubjectInfoList" :key="item.subjectType"> -->
    <template v-if="arrangeStatus">
      <tr
        v-for="item in formList.examPreviewSubjectInfoList"
        :key="item.subjectType"
      >
        <!-- <td>{{item.subjectType | subjectType}}</td> -->
        <td>{{ item.subjectType | subjectType }}</td>
        <td colspan="2">
          <!-- v-for="(val,i) in item.testQuestionList" -->
          <div
            class="divText"
            v-for="(val, i) in item.itemDTOListList"
            :key="i"
          >
            {{ i + 1 }}.{{ val.operationItemName }}
          </div>
        </td>
      </tr></template
    >
    <template v-else
      ><tr
        v-for="item in formList.examArrangementSubjectAndTitleDTOList"
        :key="item.subjectType"
      >
        <!-- <td>{{item.subjectType | subjectType}}</td> -->
        <td>{{ item.subject | subjectType }}</td>
        <td colspan="2">
          <!-- v-for="(val,i) in item.testQuestionList" -->
          <div
            class="divText"
            v-for="(val, i) in item.subjectTestList"
            :key="i"
          >
            {{ i + 1 }}.{{ val.operationTestName }}
          </div>
        </td>
      </tr></template
    >
  </table>
</template>
// rowspan="2" colspan="2"
<script>
import { basicList3 } from '@/api/exam/resultPreview.js'
import dayjs from 'dayjs'
export default {
  data() {
    return {
      formList: {},
      examId: '',
      arrangeStatus: true,
    }
  },
  methods: {
    defaultReq() {
      basicList3(this.examId).then((res) => {
        console.log('基本信息', res)
        if (res.success) {
          this.formList = res.data
          // draftStatus
        }
      })
    },
  },
  mounted() {
    this.examId = this.$route.query.examId
    if (this.examId != null) {
      this.defaultReq()
    }
  },
  filters: {
    dayList(val) {
      return dayjs(val).format('YYYY-MM-DD HH:mm')
    },
    subjectType(key) {
      switch (key) {
        case 1:
          return `物理`
        case 2:
          return `生物`
        case 3:
          return `化学`
        default:
          return `subjectType值为${key}`
      }
    },
    examModelFilters(key) {
      switch (key) {
        case 1:
          return `有纸考试`
        case 2:
          return `电子试卷`
        case 3:
          return `实验报告拍照`
        default:
          return ``
      }
    },
  },
}
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  text-align: center;
  box-sizing: border-box;
}
td {
  padding: 10px;
}
.bgColor {
  background: rgba(223, 230, 236, 1);
}
.divText {
  text-align: left;
  box-sizing: border-box;
}
</style>
