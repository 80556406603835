<template>
  <div>
    <div style="margin-bottom: 10px">匹配规则：禁止同校匹配</div>
    <el-form :inline="true" :model="condition">
      <el-form-item label="姓名">
        <el-input
          :disabled="this.disabled"
          size="medium"
          v-model="condition.teacherName"
          @change="changVal"
        ></el-input>
      </el-form-item>
      <el-form-item label="账号">
        <el-input
          :disabled="this.disabled"
          size="medium"
          v-model="condition.teacherAccount"
          @change="changVal"
        ></el-input>
      </el-form-item>
      <el-form-item label="科目">
        <el-select
          :disabled="disabled"
          size="medium"
          v-model="condition.subjectType"
          @change="changVal"
          placeholder="全部"
          class="placeholderColor"
        >
          <el-option label="全部" :value="0"></el-option>
          <el-option
            :label="val | subjectTypeFilters"
            :value="val"
            v-for="val in this.conditionShow.subjectList"
            :key="val"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="身份">
        <el-select
          :disabled="disabled"
          size="medium"
          v-model="condition.teacherType"
          @change="changVal"
          placeholder="全部"
          class="placeholderColor"
        >
          <el-option label="全部" :value="0"></el-option>
          <el-option
            :label="val | teacherTypeFilters"
            :value="val"
            v-for="val in this.conditionShow.teacherTypeList"
            :key="val"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属区县">
        <el-select
          :disabled="disabled"
          size="medium"
          v-model="condition.orgAreaId"
          @change="changVal"
          placeholder="全部"
          class="placeholderColor"
        >
          <el-option label="全部" :value="0"></el-option>
          <el-option
            :label="val.name"
            :value="val.sysOrgAreaId"
            v-for="val in this.conditionShow.sysOrgAreaList"
            :key="val.sysOrgAreaId"
          ></el-option>
          <el-option
            :label="val.name"
            :value="val.sysOrgAreaId"
            v-for="val in this.conditionShow.sysOrgAreaDTOList"
            :key="val.sysOrgAreaId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属学校">
        <el-select
          :disabled="this.disabled"
          size="medium"
          v-model="condition.orgSchoolId"
          @change="changVal"
          placeholder="全部"
          class="placeholderColor"
        >
          <el-option label="全部" :value="0"></el-option>
          <el-option
            :label="val.name"
            :value="val.sysOrgSchoolId"
            v-for="val in this.conditionShow.sysOrgSchoolList"
            :key="val.sysOrgSchoolId"
          ></el-option>
          <el-option
            :label="val.name"
            :value="val.sysOrgSchoolId"
            v-for="val in this.conditionShow.sysOrgSchoolDTOList"
            :key="val.sysOrgSchoolId"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button
          v-throttle
          size="medium"
          v-if="this.examId != null"
          @click="emptyBtn"
          type="primary"
          icon="el-icon-refresh-left"
        ></el-button>
      </el-form-item>
    </el-form>
    <!-- 表格 -->
    <el-table
      header-align="center"
      border
      :header-cell-style="{ background: '#DFE6EC' }"
      :data="tableData"
      stripe
      style="width: 100%"
    >
      <el-table-column
        align="center"
        prop="teacherName"
        label="姓名"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="teacherAccount"
        label="账号"
      ></el-table-column>
      <el-table-column align="center" label="科目">
        <template slot-scope="scope">{{
          scope.row.subjectType | subjectTypeFilters
        }}</template>
      </el-table-column>
      <el-table-column align="center" label="身份">
        <template slot-scope="scope">{{
          scope.row.teacherType | teacherTypeFilters
        }}</template>
      </el-table-column>
      <!-- <el-table-column align="center" prop="sysOrgArea.name" label="所属区县"></el-table-column>
      <el-table-column align="center" prop="sysOrgSchool.name" label="所属学校"></el-table-column>-->
      <el-table-column
        align="center"
        prop="userOrgDTOList[0].sysOrgAreaRegionName"
        label="所属区县"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="userOrgDTOList[0].sysOrgSchoolName"
        label="所属学校"
      ></el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="tableDataPagination.pageIndex"
      :page-sizes="[10, 20, 50, 100, 150, 200]"
      :page-size="tableDataPagination.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableDataPagination.total"
    ></el-pagination>
  </div>
</template>

<script>
import {
  invigilationCondition,
  invigilationList,
  arrangeInvigilationCondition,
  arrangeInvigilationList,
} from '@/api/exam/resultPreview.js'
export default {
  data() {
    return {
      // 分页
      tableDataPagination: {
        // 当前页
        pageIndex: 1,
        // 页大小
        pageSize: 10,
        // 页数
        pageTotal: 1,
        // 总数量
        total: 0,
      },
      examId: '',
      condition: {},
      conditionShow: {},
      tableData: [],
      disabled: true,
    }
  },
  methods: {
    emptyBtn() {
      this.condition = {}
      this.reqFunc()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.tableDataPagination.pageSize = val
      this.reqFunc()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.tableDataPagination.pageIndex = val
      this.reqFunc()
    },
    // 条件处理
    changVal() {
      for (let key in this.condition) {
        if (
          this.condition[key] == 0 ||
          this.condition[key] == '' ||
          this.condition[key] == undefined
        ) {
          delete this.condition[key]
        }
      }
      this.tableDataPagination.pageIndex = 1
      console.log('条件', this.condition)
      this.reqFunc()
    },
    arrangeDefaultCondition() {
      arrangeInvigilationCondition(this.examId).then((res) => {
        console.log('排考后监考老师信息', res)
        if (res.success) {
          this.conditionShow = res.data
          console.log(this.conditionShow)
        }
      })
    },
    arrangeDefaultReq() {
      arrangeInvigilationList(
        this.examId,
        this.tableDataPagination.pageIndex,
        this.tableDataPagination.pageSize,
        this.condition
      ).then((res) => {
        console.log('排考后监考老师', res)
        if (res.success) {
          this.tableData = res.data
          this.tableDataPagination = {
            // 当前页
            pageIndex: res.pageIndex,
            // 页大小
            pageSize: res.pageSize,
            // 页数
            pageTotal: res.pageTotal,
            // 总数量
            total: res.total,
          }
        }
      })
    },
    reqFunc() {
      this.arrangeDefaultReq()
    },
    conditionFunc() {
      this.arrangeDefaultCondition()
    },
  },
  mounted() {
    this.examId = this.$route.query.examId
    if (this.examId != null) {
      this.disabled = false
      this.reqFunc()
      this.conditionFunc()
    }
  },
  filters: {
    teacherTypeFilters(key) {
      switch (key) {
        case 1:
          return `监考组长`
        case 2:
          return `监考组员`
        case 3:
          return `阅卷组长`
        case 4:
          return `阅卷老师`
        default:
          return `${val}是错误的teacherType`
      }
    },
    subjectTypeFilters(key) {
      switch (key) {
        case 1:
          return `物理`
        case 2:
          return `生物`
        case 3:
          return `化学`
        default:
          return `没有该学科`
      }
    },
  },
  watch: {
    'condition.orgAreaId'() {
      this.condition.orgSchoolId = ''
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-form--inline .el-form-item__content {
  width: 118px !important;
}
::v-deep .el-input--suffix .el-input__inner {
  padding-left: 10px;
}
</style>
