// 查询考试结果预览
import { request, noTimeOutReq } from '@/utils/request.js'

// 检测考试安排数据
export function check (examId) {
  return request({
    url: `/exam/arrangement/check/${examId}`,
    method: 'post'
  })
}
// 查询考试安排检查数据信息
export function checkList (examId) {
  return request({
    url: `/exam/arrangement/get/check/${examId}`,
    // url: `/exam/arrangement/get/${examId}`,
    // url: `/exam/arrangement/get/check/${examId}`,
    method: 'post',
  })
}


// 编排考试
export function arrangeExam (examId) {
  return noTimeOutReq({
    url: `/exam/arrange/${examId}`,
    method: 'post',
  })
}

// 编排考试
export function cancelArrangeExam (examId) {
  return noTimeOutReq({
    url: `/exam/arrange/cancel/${examId}`,
    method: 'post',
  })
}

// 发布考试
export function publishExam (examId) {
  return request({
    url: `/exam/publish/${examId}`,
    method: 'post',
  })
}

// 终止考试
// export function terminationExam (examId) {
//   return request({
//     url: `/exam/termination/${examId}`,
//     method: 'post',
//   })
// }

/** 基本信息 */
// 查询考试安排预览信息
export function basicList (examId) {
  return request({
    url: `/exam/arrangement/get/preview/${examId}`,
    method: 'post',
  })
}

export function basicList2 (examId) {
  return request({
    url: `/exam/arrangement/get/${examId}`,
    method: 'post',
  })
}
// 排考后
export function basicList3 (examId) {
  return request({
    url: `/exam/get/preview/${examId}`,
    method: 'post',
  })
}

// 排考后

/** 考试规则 */
// // 查询考试安排规则
// export function basicList (examId) {
//   return request({
//     url: `/exam/arrangement/rule/get/${examId}`,
//     method: 'post',
//   })
// }
// 排考后

/** 考场安排 */
// 查询考场排条件
export function placeCondition (examId) {
  return request({
    url: `/exam/arrangement/place/get/condition/${examId}`,
    method: 'post',
  })
}
// 条件查询考场排考信息
export function placeList (examId, pageIndex, pageSize, data) {
  return request({
    url: `/exam/arrangement/place/list/${examId}/${pageIndex}/${pageSize}`,
    method: 'post',
    data
  })
}

// 排考后
// 查询考场排条件
export function arrangePlaceCondition (examId) {
  return request({
    url: `/exam/place/get/condition/${examId}`,
    method: 'post',
  })
}
// 条件查询考场排考信息
export function arrangePlaceList (examId, pageIndex, pageSize, data) {
  return request({
    url: `/exam/place/list/${examId}/${pageIndex}/${pageSize}`,
    method: 'post',
    data
  })
}

/** 监考安排 */
// 查询监考老师筛选信息
export function invigilationCondition (examId) {
  return request({
    url: `/exam/arrangement/invigilation/get/condition/${examId}`,
    method: 'post',
  })
}
// 分页条件查询监考老师信息
export function invigilationList (examId, pageIndex, pageSize, data) {
  return request({
    url: `/exam/arrangement/invigilation/list/${examId}/${pageIndex}/${pageSize}`,
    method: 'post',
    data
  })
}

// 排考后
// 查询监考老师筛选信息
export function arrangeInvigilationCondition (examId) {
  return request({
    url: `/exam/invigilation/get/condition/${examId}`,
    method: 'post',
  })
}
// 分页条件查询监考老师信息
export function arrangeInvigilationList (examId, pageIndex, pageSize, data) {
  return request({
    url: `/exam/invigilation/list/${examId}/${pageIndex}/${pageSize}`,
    method: 'post',
    data
  })
}

/** 考生安排 */
// 分页查询考试安排考生
export function examineeCondition (examId) {
  return request({
    url: `/exam/arrangement/examinee/list/condition/${examId}`,
    method: 'post',
  })
}

// 分页查询考试安排考生
export function examineeList (examId, pageIndex, pageSize, data) {
  return request({
    url: `/exam/arrangement/examinee/list/preview/${examId}/${pageIndex}/${pageSize}`,
    method: 'post',
    data
  })
}

// 排考后
// 分页查询考试安排考生
export function arrangementExamineeCondition (examId) {
  return request({
    url: `/exam/examinee/get/condition/${examId}`,
    method: 'post',
  })
}

// 分页查询考试安排考生
export function arrangementExamineeList (examId, pageIndex, pageSize, data) {
  return request({
    url: `/exam/examinee/list/${examId}/${pageIndex}/${pageSize}`,
    method: 'post',
    data
  })
}


// 结果预览
// 查询考试考场座位考题信息
export function getSeatArrangeApi (examId) {
  return request({
    url: `/exam/place/list/seat/arrange/${examId}`,
    method: 'post'
  })
}

// 修改座位考题
export function setSeatArrangeApi (examId, data) {
  return request({
    url: `/exam/place/change/seat/${examId}`,
    method: 'post',
    data
  })
}

// 修改座位考题
export function applySameExamSubject (examId, examPlaceId) {
  return request({
    url: `/exam/place/apply/same/examSubject/${examId}/${examPlaceId}`,
    method: 'post'
  })
}