<template>
  <div>
    <template v-if="markingData.markRuleStatus == 2">
      <div class="li">
        <span class="liL">自定义分数段</span>
        <span class="liR">低分：{{ markingData.markSubjects[0].lowScore }}%</span>
        <span class="liR">及格：{{ markingData.markSubjects[0].passingScore }}%</span>
        <span class="liR">优分：{{ markingData.markSubjects[0].excellentScore }}%</span>
        <span class="liR">高分：{{ markingData.markSubjects[0].highScore }}%</span>
      </div>
      <div class="li">
        <span class="liL">评卷方式</span>
        <span class="liR">{{
          markingData.markSubjects[0].markType | markTypeFilters
        }}</span>
      </div>
      <div class="li">
        <span class="liL">评卷规则</span>
        <span class="liR" v-show="markingData.markSubjects[0].markType == 1">
          {{ markingData.markSubjects[0].markRule | markRuleFilters }}
          <template v-if="markingData.markSubjects[0].markRule != 1">，分差阈值为{{
            markingData.markSubjects[0].markThreshold
          }}</template>
        </span>
        <span class="liR" v-show="markingData.markSubjects[0].markType !== 1">每位老师评阅的考生数为：{{
          markingData.markSubjects[0].sceneMarkNum
        }}</span>
      </div>
      <div class="li" v-show="markingData.markSubjects[0].markType == 1">
        <span class="liL">回评审查</span>
        <span class="liR">
          {{ markingData.markSubjects[0].reviewStatus ? '启用' : '关闭' }}
          <template v-if="markingData.markSubjects[0].reviewStatus">，轮次容量为{{
            markingData.markSubjects[0].reviewCapacity
          }}，分差警示为{{
  markingData.markSubjects[0].reviewThreshold
}}</template>
        </span>
      </div>
    </template>
    <template v-else>
      <div style="text-align: center">暂无</div>
    </template>
  </div>
</template>

<script>
import { markRule } from '@/api/schoolManufacturer/examInfo.js'
export default {
  // 阅卷规则
  data () {
    return {
      examId: '',
      markingData: {
        examId: 0,
        markRuleId: 0,
        markRuleStatus: 0,
        markSubjects: [
          {
            excellentScore: 0,
            finish: true,
            highScore: 0,
            lowScore: 0,
            markRule: 0,
            markRuleId: 0,
            markSubjectId: 0,
            markThreshold: 0,
            markType: 0,
            matchRule: 0,
            passingScore: 0,
            reviewCapacity: 0,
            reviewStatus: 0,
            reviewThreshold: 0,
            sceneMarkNum: 0,
            subjectType: 0,
          },
        ],
        markTeachers: [
          {
            examMarkTeacherId: 0,
            markRuleId: 0,
            orgAreaId: 0,
            orgAreaName: '',
            orgSchoolId: 0,
            orgSchoolName: '',
            status: 0,
            subjectType: 0,
            teacherId: 0,
            teacherName: '',
            teacherType: 0,
          },
        ],
      },
    }
  },
  mounted () {
    this.examId = this.$route.query.examId
    if (this.examId != null) {
      this.markRuleFunc()
    }
  },
  methods: {
    markRuleFunc () {
      markRule(this.examId).then((res) => {
        console.log('阅卷规则', res)
        if (res.success) {
          this.markingData = res.data
        }
      })
    },
  },
  filters: {
    markTypeFilters (key) {
      switch (key) {
        case 1:
          return `考后评分`
        case 2:
          return `现场评分`
        case 3:
          return `直播评分`
        default:
          return ``
      }
    },
    markRuleFilters (key) {
      switch (key) {
        case 1:
          return `单评制`
        case 2:
          return `多评制`
        default:
          return ``
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.li {
  width: 100%;
  margin-bottom: 5px;

  .liL {
    width: 100px;
    display: inline-block;
    text-align: right;
    color: #999;
    margin-right: 20px;
  }

  .liR {
    margin-right: 16px;
  }
}
</style>
