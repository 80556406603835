import { request, noTimeOutReq } from '@/utils/request.js'

//获取考试信息
export function getCompanyExamApi (companyCityExamId) {
  return request({
    url: `/company/join/exam/data/query/${companyCityExamId}`,
    method: 'post',
  })
}

//确认考试信息
export function confirmCompanyExamApi (companyCityExamId) {
  return request({
    url: `/company/join/exam/data/confirm/status/${companyCityExamId}`,
    method: 'post',
  })
}

//阅卷规则
export function markRule (examId) {
  return request({
    url: `/exam/score/admin/mark/rule/get`,
    method: 'post',
    params: { examId : examId}
  })
}

//获取市级考试数据信息
export function getCityPlatformExamInfoApi(companyCityExamId) {
  return request({
    url: `/company/join/exam/info/list/${companyCityExamId}`,
    method: 'post'
  })
}

 //终止并删除市级考试数据
 export function terminationAndDeleteCityExamDataApi(examId, companyCityExamId) {
  return request({
    url: `/company/join/exam/info/terminationAndDelete/${examId}/${companyCityExamId}`,
    method: 'post'
  })
}