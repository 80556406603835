<template>
  <div>
    <el-form :inline="true" :model="condition">
      <el-form-item label="姓名">
        <el-input
          :disabled="disabled"
          size="medium"
          v-model="condition.studentName"
          @change="changVal"
        ></el-input>
      </el-form-item>
      <el-form-item label="学号">
        <el-input
          :disabled="disabled"
          size="medium"
          v-model="condition.studentCode"
          @change="changVal"
        ></el-input>
      </el-form-item>
      <el-form-item label="科目">
        <el-select
          :disabled="disabled"
          size="medium"
          v-model="condition.subjectType"
          @change="changVal"
          placeholder="全部"
          class="placeholderColor"
        >
          <el-option label="全部" :value="0"></el-option>
          <el-option
            :label="val | subjectTypeFilters"
            :value="val"
            v-for="val in this.conditionShow.subjectTypeList"
            :key="val"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属区县">
        <el-select
          :disabled="disabled"
          size="medium"
          v-model="condition.orgAreaId"
          placeholder="全部"
          @change="changVal"
          class="placeholderColor"
        >
          <el-option label="全部" :value="0"></el-option>
          <el-option
            :label="val.name"
            :value="val.sysOrgAreaId"
            v-for="val in this.conditionShow.sysOrgAreaList"
            :key="val.sysOrgAreaId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属学校">
        <el-select
          :disabled="disabled"
          size="medium"
          v-model="condition.orgSchoolId"
          placeholder="全部"
          @change="changVal"
          class="placeholderColor"
        >
          <el-option label="全部" :value="0"></el-option>
          <el-option
            :label="val.name"
            :value="val.sysOrgSchoolId"
            v-for="val in this.conditionShow.sysOrgSchoolList"
            :key="val.sysOrgSchoolId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="考试区县">
        <el-select
          :disabled="disabled"
          size="medium"
          v-model="condition.examSysOrgAreaId"
          placeholder="全部"
          @change="changVal"
          class="placeholderColor"
        >
          <el-option label="全部" :value="0"></el-option>
          <el-option
            :label="val.name"
            :value="val.sysOrgAreaId"
            v-for="val in this.conditionShow.examOrgAreaList"
            :key="val.sysOrgAreaId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="考点">
        <el-select
          :disabled="disabled"
          size="medium"
          v-model="condition.examSysOrgExamPlaceId"
          placeholder="全部"
          @change="changVal"
          class="placeholderColor"
        >
          <el-option label="全部" :value="0"></el-option>
          <el-option
            :label="val.name"
            :value="val.sysOrgExamPlaceId"
            v-for="val in this.conditionShow.examOrgExamPlaceList"
            :key="val.sysOrgExamPlaceId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="考场">
        <el-select
          :disabled="disabled"
          size="medium"
          v-model="condition.examSysOrgExamPlaceRoomId"
          placeholder="全部"
          @change="changVal"
          class="placeholderColor"
        >
          <el-option label="全部" :value="0"></el-option>
          <el-option
            :label="val.name"
            :value="val.sysOrgExamPlaceRoomId"
            v-for="val in this.conditionShow.examOrgExamPlaceRoomList"
            :key="val.sysOrgExamPlaceRoomId"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="考生状态">
        <el-select
          size="medium"
          v-model="condition.examineeStatus"
          placeholder="全部"
          @change="changVal"
        >
          <el-option label="全部" :value="0"></el-option>
          <el-option
            :label="val"
            :value="val"
            v-for="val in this.conditionShow.examineeStatus"
            :key="val"
          ></el-option>
        </el-select>
      </el-form-item>-->
      <el-form-item>
        <el-button
          v-throttle
          size="medium"
          v-if="this.examId != null"
          @click="emptyBtn"
          type="primary"
          icon="el-icon-refresh-left"
        ></el-button>
      </el-form-item>
    </el-form>
    <!-- 表格 -->
    <el-table
      header-align="center"
      border
      :header-cell-style="{ background: '#DFE6EC' }"
      :data="tableData"
      stripe
      style="width: 100%"
    >
      <el-table-column
        align="center"
        prop="studentName"
        label="姓名"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="studentCode"
        label="学号"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="sysOrgAreaName"
        label="所属区县"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="sysOrgSchoolName"
        label="所属学校"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="sysOrgSchoolClassName"
        label="所属班级"
      ></el-table-column>
      <el-table-column align="center" label="考试科目">
        <template slot-scope="scope">{{
          scope.row.subjectType | subjectTypeFilters
        }}</template>
      </el-table-column>
      <el-table-column align="center" prop="examTime" label="考试时间">
        <template slot-scope="scope">{{
          scope.row.examTime | dayList
        }}</template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="examSysOrgAreaName"
        label="考试区县"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="examSysOrgExamPlaceName"
        label="考点"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="examSysOrgExamPlaceRoomName"
        label="考场"
      ></el-table-column>
      <!-- <el-table-column align="center" prop="examineeStatus" label="考生状态">
        <template slot-scope="scope">{{scope.row.examineeStatus | examineeStatusFilters}}</template>
      </el-table-column>-->
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="tableDataPagination.pageIndex"
      :page-sizes="[10, 20, 50, 100, 150, 200]"
      :page-size="tableDataPagination.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableDataPagination.total"
    ></el-pagination>
  </div>
</template>

<script>
let _this
import dayjs from 'dayjs'
import {
  examineeCondition,
  examineeList,
  arrangementExamineeCondition,
  arrangementExamineeList,
} from '@/api/exam/resultPreview.js'
export default {
  props: ['rulesArrangeModel'],
  data() {
    return {
      // 分页
      tableDataPagination: {
        // 当前页
        pageIndex: 1,
        // 页大小
        pageSize: 10,
        // 页数
        pageTotal: 1,
        // 总数量
        total: 0,
        subjectType: undefined,
      },
      examId: '',
      condition: {},
      conditionShow: {},
      tableData: [],
      disabled: true,
    }
  },
  beforeCreate() {
    _this = this
  },
  methods: {
    emptyBtn() {
      this.condition = {}
      this.reqFunc()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.tableDataPagination.pageSize = val
      this.reqFunc()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.tableDataPagination.pageIndex = val
      this.reqFunc()
    },
    // 条件处理
    changVal() {
      for (let key in this.condition) {
        if (
          this.condition[key] == 0 ||
          this.condition[key] == '' ||
          this.condition[key] == undefined
        ) {
          delete this.condition[key]
        }
      }
      this.tableDataPagination.pageIndex = 1
      this.reqFunc()
    },
    arrangeDefaultCondition() {
      arrangementExamineeCondition(this.examId).then((res) => {
        console.log('排考后考生条件查询', res)
        if (res.success) {
          this.conditionShow = res.data
        }
      })
    },
    arrangeDefaultReq() {
      arrangementExamineeList(
        this.examId,
        this.tableDataPagination.pageIndex,
        this.tableDataPagination.pageSize,
        this.condition
      ).then((res) => {
        console.log('排考后学生信息', res)
        if (res.success) {
          this.tableData = res.data
          this.tableDataPagination.total = res.total
          if (this.rulesArrangeModel == 2) {
            this.tableData.forEach((val) => {
              !val.examSysOrgAreaName && (val.examSysOrgAreaName = '待录入')
              !val.examSysOrgExamPlaceName &&
                (val.examSysOrgAreaName = '待录入')
              !val.examSysOrgExamPlaceRoomName &&
                (val.examSysOrgAreaName = '待录入')
            })
          }
        }
      })
    },
    reqFunc() {
      this.arrangeDefaultReq()
    },
    conditionFunc() {
      this.arrangeDefaultCondition()
    },
  },
  mounted() {
    this.examId = this.$route.query.examId
    if (this.examId != null) {
      this.disabled = false
      this.reqFunc()
      this.conditionFunc()
    }
  },
  filters: {
    teacherTypeFilters(key) {
      switch (key) {
        case 1:
          return `监考组长`
        case 2:
          return `监考组员`
        case 3:
          return `阅卷组长`
        case 4:
          return `阅卷老师`
        default:
          return `${val}是错误的teacherType`
      }
    },
    dayList(val) {
      if (val) {
        return dayjs(val).format('YYYY-MM-DD HH:mm')
      } else {
        if (_this.rulesArrangeModel == 2) return `待录入`
        return `待排考`
      }
    },
    subjectTypeFilters(key) {
      switch (key) {
        case 1:
          return `物理`
        case 2:
          return `生物`
        case 3:
          return `化学`
        default:
          return `待排考`
      }
    },
    examineeStatusFilters(key) {
      switch (key) {
        case 1:
          return `未交卷`
        case 2:
          return `待阅卷`
        case 3:
          return `已阅卷`
        case 4:
          return `缺考`
        case 5:
          return `取消考试资格`
        default:
          if (_this.rulesArrangeModel == 2) return `待录入`
          return `待排考`
      }
    },
  },
  watch: {
    'condition.orgAreaId'() {
      this.condition.orgSchoolId = ''
      this.condition.examSysOrgAreaId = ''
      this.condition.examSysOrgAreaId = ''
      this.condition.examSysOrgExamPlaceId = ''
      this.condition.examSysOrgExamPlaceRoomId = ''
    },
    'condition.orgSchoolId'() {
      this.condition.examSysOrgAreaId = ''
      this.condition.examSysOrgExamPlaceId = ''
      this.condition.examSysOrgExamPlaceRoomId = ''
    },
    'condition.examSysOrgAreaId'() {
      this.condition.examSysOrgExamPlaceId = ''
      this.condition.examSysOrgExamPlaceRoomId = ''
    },
    'condition.examSysOrgExamPlaceId'() {
      this.condition.examSysOrgExamPlaceRoomId = ''
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-form--inline .el-form-item__content {
  width: 104px !important;
}
::v-deep .el-input--suffix .el-input__inner {
  padding-left: 10px;
}
</style>
