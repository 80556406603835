// 查询考试基本信息
import { request, noTimeOutReq } from '@/utils/request.js'

// 查询考试预览信息 
export function examInfoArrange (pageIndex, pageSize, data) {
  return request({
    url: `/exam/list/${pageIndex}/${pageSize}`,
    method: 'post',
    data
  })
}
// 查询考试预览信息 排考后
export function examInfo (pageIndex, pageSize, data) {
  return request({
    url: `/exam/arrangement/list/${pageIndex}/${pageSize}`,
    method: 'post',
    data
  })
}
// 删除考试信息
export function deleteExamInfo (examId) {
  return request({
    url: `/exam/arrangement/delete/${examId}`,
    method: 'post',
  })
}
// 复制考试信息
export function copyExamInfo (examId) {
  return request({
    url: `/exam/arrangement/copy/${examId}`,
    method: 'post',
  })
}


/**  */
// 查询考试安排检查数据信息
export function detectExam (examId) {
  return request({
    url: `/exam/arrangement/get/check/${examId}`,
    method: 'post'
  })
}

/** 基本信息 */
// 根据学科查询考试安排所需考题信息
export function getQuestions (data) {
  return request({
    url: `/exam/operation/questions/getBySubjectTypeOrExamModel`,
    method: 'post',
    data: `subjectType=${data.subjectType}&examModel=${data.examModel}`
  })
}
// 添加考试安排基础信息
export function addBase (data) {
  return request({
    url: `/exam/arrangement/add`,
    method: 'post',
    data
  })
}
// 查询考试安排预览信息
export function inquireBase (examId) {
  return request({
    url: `/exam/arrangement/get/${examId}`,
    method: 'post',
  })
}

// 查询考试安排补考预览信息
export function inquireBaseRetake (examId) {
  return request({
    url: `/exam/get/${examId}`,
    method: 'post'
  })
}
// // 查询考试安排预览信息 
// export function inquireBase (examId) {
//   return request({
//     url: `/exam/arrangement/get/preview/${examId}`,
//     method: 'post',
//   })
// }
// 更新考试安排基础信息
export function renewBase (examId, data) {
  return request({
    url: `/exam/arrangement/update/${examId}`,
    method: 'post',
    data
  })
}



/** 考试规则 */
// 添加考试安排规则
export function addRule (examId, data) {
  return request({
    // url: `/exam/arrangement/rule/add/${examId}`,
    url: `/exam/arrangement/rule/aadOrUpdate/${examId}`,
    method: 'post',
    data
  })
}
// 查询考试安排规则
export function inquireRule (examId) {
  return request({
    url: `/exam/arrangement/rule/get/${examId}`,
    method: 'post',
  })
}
// 查询考试安排规则 或 排考后
export function inquireRule2 (examId) {
  return request({
    url: `/exam/rule/get/${examId}`,
    method: 'post',
  })
}

// 更新考试安排规则
export function renewRule (examId, data) {
  return request({
    // url: `/exam/arrangement/rule/update/${examId}`,
    url: `/exam/arrangement/rule/aadOrUpdate/${examId}`,
    method: 'post',
    data
  })
}

/** 考场安排 */
// 获取配置文件的root地区 ，学校 或考点,通用接口，缓存
export function rootOr (data) {
  if (data.getExamPlace == '') data.getExamPlace = 0;
  if (data.getSchool == '') data.getSchool = 0;
  return noTimeOutReq({
    url: `/sys/org/get/root/or`,
    method: 'post',
    data: `getExamPlace=${data.getExamPlace}&getSchool=${data.getSchool}`
  })
}
export function rootOr2 (data) {
  if (data.getExamPlace == '') data.getExamPlace = 0;
  if (data.getSchool == '') data.getSchool = 0;
  if (data.sysOrgAreaId == '') data.sysOrgAreaId = 0;
  return noTimeOutReq({
    url: `/sys/org/get/or`,
    method: 'post',
    data: `getExamPlace=${data.getExamPlace}&getSchool=${data.getSchool}&sysOrgAreaId=${data.sysOrgAreaId}`
  })
}
// 添加考试安排考点考场
export function addPlace (examId, data) {
  return noTimeOutReq({
    // url: `/exam/arrangement/place/add/${examId}`,
    url: `/exam/arrangement/place/addAndUpdate/${examId}`,
    method: 'post',
    data
  })
}
// 查询考试安排考点考场
export function inquirePlace (examId) {
  return noTimeOutReq({
    url: `/exam/arrangement/place/get/${examId}`,
    method: 'post',
  })
}

export function renewPlace (examId, data) {
  return noTimeOutReq({
    // url: `/exam/arrangement/place/update/${examId}`,
    url: `/exam/arrangement/place/addAndUpdate/${examId}`,
    method: 'post',
    data
  })
}
// add 添加 inquire 查询 renew 更新

/** 监考安排 */
// 查询监考老师筛选信息
export function filterTeacher (examId) {
  return request({
    url: `/exam/arrangement/invigilation/get/condition/${examId}`,
    method: 'post',
  })
}
// 条件展示用户,主要是监考老师，阅卷老师
export function teacherList (data) {
  return noTimeOutReq({
    url: `/user/role/query/list`,
    method: 'post',
    data
  })
}
// 添加考试安排监考老师
export function addTeacher (examId, data) {
  return noTimeOutReq({
    // url: `/exam/arrangement/invigilation/add/${examId}`,
    url: `/exam/arrangement/invigilation/addAndUpdate/${examId}`,
    method: 'post',
    data
  })
}
// 查询考试安排监考老师
export function InvigilationTeacher2 (examId) {
  return noTimeOutReq({
    url: `/exam/arrangement/invigilation/get/${examId}`,
    method: 'post',
  })
}
// 查询考试安排监考老师
export function InvigilationTeacher (examId, pageIndex, pageSize, data) {
  return noTimeOutReq({
    url: `/exam/arrangement/invigilation/list/${examId}/${pageIndex}/${pageSize}`,
    method: 'post',
    data
  })
}


// 分页条件查询监考老师信息
export function invigilationList (examId, pageIndex, pageSize, data) {
  return noTimeOutReq({
    url: `/exam/arrangement/invigilation/list/${examId}/${pageIndex}/${pageSize}`,
    method: 'post',
    data
  })
}
// 更新考试安排监考老师
export function renewTeacher (examId, data) {
  return noTimeOutReq({
    // url: `/exam/arrangement/invigilation/update/${examId}`,
    url: `/exam/arrangement/invigilation/addAndUpdate/${examId}`,
    method: 'post',
    data
  })
}

/** 考生安排 */

// 查询学校学生的毕业年份
export function orgSchoolId (orgSchoolId) {
  return request({
    url: `/user/student/admin/get/graduationYear/${orgSchoolId}`,
    method: 'post',
  })
}

// 条件添加考试考生安排
export function conditionalAdd (examId, data) {
  return noTimeOutReq({
    // url: `/exam/arrangement/examinee/add/query/${examId}`,
    url: `/exam/arrangement/examinee/addOrUpdate/query/${examId}`,
    method: 'post',
    data
  })
}
// 添加考试安排考生
export function addExaminee (examId, data) {
  return request({
    url: `/exam/arrangement/examinee/add/${examId}`,
    method: 'post',
    data
  })
}
// 取消
// 添加考试安排考生
export function renewAddExaminee (examId, data) {
  return request({
    url: `/exam/arrangement/examinee/update/${examId}`,
    method: 'post',
    data
  })
}
// 取消
// 条件更新考试考生安排 
export function conditionalRenewExaminee (examId, data) {
  return request({
    url: `/exam/arrangement/examinee/update/query/${examId}`,
    method: 'post',
    data
  })
}

// 查询考试考生过滤条件
export function conditionList (examId) {
  return request({
    url: `/exam/arrangement/examinee/list/condition/${examId}`,
    method: 'post',
  })
}
// 分页查询考生
export function examineeList (examId, pageIndex, pageSize, data) {
  return request({
    url: `/exam/arrangement/examinee/list/${examId}/${pageIndex}/${pageSize}`,
    method: 'post',
    data
  })
}
// 批量或条件删除考生
export function deleteExaminee (examId, data) {
  return request({
    url: `/exam/arrangement/examinee/delete/${examId}`,
    method: 'post',
    data
  })
}
// 更新考试安排考生
export function renewExaminee (examId, data) {
  return request({
    url: `/exam/arrangement/examinee/update/${examId}`,
    method: 'post',
    data
  })
}

/** */
// 终止考试
export function terminationExam (examId) {
  return request({
    url: `/exam/termination/${examId}`,
    method: 'post',
  })
}

// 结束考试
export function endExam (examId) {
  return request({
    url: `/exam/end/${examId}`,
    method: 'post',
  })
}


// 更新考试考生登录方式
export function examLoginType (data) {
  return request({
    url: `/exam/config/update/examineeLoginType`,
    method: 'post',
    data
  })
}

//下载考试资料
export function downloadExamDataQuery (examId, data) {
  return noTimeOutReq({
    url: `/exam/download/data/${examId}`,
    method: 'post',
    data
  })
}


// 下载准考证
// export function downloadTickets (examId) {
//   return noTimeOutReq({
//     url: `/admin/tickets/download/${examId}`,
//     method: 'post'
//   })
// }
export function downloadTickets (examId, pdfSize, data) {
  return noTimeOutReq({
    url: `/admin/tickets/download/${examId}?pdfSize=${pdfSize}`,
    method: 'post',
    data
  })
}

// 下载 压缩准考证
export function compressDownloadTickets (examId) {
  return noTimeOutReq({
    url: `/admin/tickets/download/class/${examId}`,
    method: 'post'
  })
}


// 批量移除
// 全体情况

// 结果预览


/**
 * 补考安排
 */
// 添加考试补考安排基础信息
export function makeupAdd (data) {
  return request({
    url: `/exam/arrangement/makeup/add`,
    method: 'post',
    data
  })
}
// 更新考试补考安排基础信息
export function makeupAddUpdate (examId, data) {
  return request({
    url: `/exam/arrangement/makeup/update/${examId}`,
    method: 'post',
    data
  })
}
// 添加或更新考试补考安排时间段
export function makeupRule (examId, data) {
  return request({
    url: `/exam/arrangement/rule/makeup/aadOrUpdate/${examId}`,
    method: 'post',
    data
  })
}
// 添加或更新考试补考安排考点考场
export function makeupPlace (examId, data) {
  return request({
    url: `/exam/arrangement/place/makeup/addAndUpdate/${examId}`,
    method: 'post',
    data
  })
}
// 添加或更新考试补考安排监考老师
export function makeupInvigilation (examId, data) {
  return noTimeOutReq({
    url: `/exam/arrangement/invigilation/makeup/addAndUpdate/${examId}`,
    method: 'post',
    data
  })
}
// 一键添加补考考生
export function makeupExaminee (examId) {
  return request({
    url: `/exam/arrangement/examinee/makeup/add/${examId}`,
    method: 'post'
  })
}
// 批量或条件删除补考考生
export function makeupExamineeDelete (examId, data) {
  return request({
    url: `/exam/arrangement/examinee/makeup/delete/${examId}`,
    method: 'post',
    data
  })
}
// 分页条件查询补考考生
export function makeupExamineeList (examId, pageIndex, pageSize, data) {
  return request({
    url: `/exam/arrangement/examinee/makeup/list/${examId}/${pageIndex}/${pageSize}`,
    method: 'post',
    data
  })
}
//查询考生登录方式
export function getExamineeLoginType (examId) {
  return request({
    url: `/exam/config/get/examineeLoginType/${examId}`,
    method: 'post'
  })
}

//下载考试补考考生Excel
export function downExamMakeupExamineeExcelApi (examId) {
  return request({
    url: `/exam/download/makeup/examinee/${examId}`,
    method: 'post'
  })
}

// 查询考试补考考生
export function getMakeupExamineeList (examId, pageIndex, pageSize) {
  return request({
    url: `/exam/makeup/examinee/list/${examId}/${pageIndex}/${pageSize}`,
    method: 'post'
  })
}

//导入补考名单
export function importExamMakeupExamineeExcelApi (examId, data) {
  return noTimeOutReq({
    url: `/exam/arrangement/examinee/import/makeup/excel/${examId}`,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'post',
    data
  })
}
//导入考试考生excel
export function uploadExamineeApi (examId, data) {
  return noTimeOutReq({
    url: `/exam/arrangement/examinee/import/excel/${examId}`,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'post',
    data
  })
}
//下载导入模板
export function DownloadTemplateApi () {
  return noTimeOutReq({
    url: `/admin/excel/exam/examinee/excel/download`,
    method: 'post'
  })
}
