<template>
  <div id="content">
    <div>
      <el-button v-throttle size="medium" @click="goBack">返回</el-button>
    </div>

    <div style="margin: 15px 0">
      <el-button
        v-throttle
        type="primary"
        size="medium"
        :disabled="this.examId != null || this.examDataStatus == 3"
        @click="getCompanyExam"
        >获取考试信息</el-button
      >
      <el-button
        v-throttle
        type="danger"
        size="medium"
        :disabled="this.examId == null || this.examDataStatus == 3"
        @click="terminationAndDeleteCityExamData"
        >终止并删除市级考试数据</el-button
      >
      <el-button
        v-throttle="true"
        type="primary"
        size="medium"
        :disabled="this.examId == null || this.examDataStatus == 3"
        @click="confirmCompanyExam"
        >确认考试信息</el-button
      >
    </div>

    <div style="margin: 15px 0">
      <span v-if="this.gmtDataGetting != null"
        >最近请求时间：{{ gmtDataGetting | dayList }}</span
      >
      <span v-if="this.gmtDataGetting == null">最近请求时间：未请求</span>
      <span v-if="this.gmtDataConfirm != null" style="margin-left: 50px"
        >确认时间：{{ gmtDataConfirm | dayList }}</span
      >
      <span v-if="this.gmtDataConfirm == null" style="margin-left: 50px"
        >确认时间：未确认</span
      >
    </div>
    <!-- 表格 -->
    <div class="cardBox" v-for="(item, i) in comList" :key="item.com + examId">
      <div class="cardTit">
        <span>{{ i + 1 }}</span>
        {{ item.name }}
      </div>
      <div class="cardContent">
        <component
          :is="item.com"
          :rulesArrangeModel="rulesArrangeModel"
        ></component>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getCompanyExamApi,
  confirmCompanyExamApi,
  getCityPlatformExamInfoApi,
  terminationAndDeleteCityExamDataApi,
} from '@/api/schoolManufacturer/examInfo.js'
import { isEmpty, debounce } from '@/utils/util.js'
import { inquireRule } from '@/api/exam/exam.js'
import basic from './examInfoComponent/1.basic.vue'
import invigilation from './examInfoComponent/4.invigilation .vue'
import candidate from './examInfoComponent/5.Candidate.vue'
import marking from './examInfoComponent/marking.vue'
import dayjs from 'dayjs'
export default {
  name: 'examInfo',
  components: {
    basic,
    invigilation,
    candidate,
    marking,
  },
  data() {
    return {
      urlParam: {
        companyCityExamId: 0,
      },
      invigilationQueryParam: {
        name: '',
        gender: 'shanghai',
      },
      examineeQueryParam: {
        name: '',
        gender: 'shanghai',
      },
      invigilationLoading: false,
      invigilationInfo: [],
      examineeLoading: false,
      examineeInfo: [],
      invigilationPageParam: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
      },
      examineePageParam: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
      },
      comList: [
        { name: '基本信息', com: 'basic' },
        { name: '监考信息', com: 'invigilation' },
        { name: '考生信息', com: 'candidate' },
        { name: '阅卷规则', com: 'marking' },
      ],
      rulesArrangeModel: 1,
      examId: '',
      gmtDataGetting: 0,
      gmtDataConfirm: 0,
      examDataStatus: 0,
    }
  },
  async created() {
    this.examId = this.$route.query.examId
    this.urlParam.companyCityExamId = this.$route.query.companyCityExamId
    this.getCityExamInfo()
    if (this.examId != null) {
      await this.getInquireRule()
    }
  },
  methods: {
    goBack() {
      this.$router.push({
        path: '/schoolManufacturer/examManage',
        query: {},
      })
    },
    //获取考试信息
    getCompanyExam() {
      if (
        isEmpty(this.urlParam.companyCityExamId) ||
        this.urlParam.companyCityExamId == 0
      ) {
        return
      }
      getCompanyExamApi(this.urlParam.companyCityExamId).then((res) => {
        if (res.success) {
          this.$message.success('获取成功')
          setTimeout(() => {
            this.$router.replace(
              `/schoolManufacturer/examInfo?examId=${res.data}&companyCityExamId=${this.urlParam.companyCityExamId}`
            )
            this.$router.go(0)
          }, 500)
        } else {
          this.msgWarning(res.msg)
        }
      })
    },
    //确认考试信息
    // confirmCompanyExam() {
    //   if (
    //     isEmpty(this.urlParam.companyCityExamId) ||
    //     this.urlParam.companyCityExamId == 0
    //   ) {
    //     return
    //   }
    //   confirmCompanyExamApi(this.urlParam.companyCityExamId).then((res) => {
    //     if (res.success) {
    //       this.$message.success('确认成功')
    //       this.getCityExamInfo()
    //     } else {
    //       this.msgWarning(res.msg)
    //     }
    //   })
    // },
    confirmCompanyExam: debounce(function () {
      if (
        isEmpty(this.urlParam.companyCityExamId) ||
        this.urlParam.companyCityExamId == 0
      ) {
        return
      }
      confirmCompanyExamApi(this.urlParam.companyCityExamId).then((res) => {
        if (res.success) {
          this.$message.success('确认成功')
          this.getCityExamInfo()
        } else {
          this.msgWarning(res.msg)
        }
      })
    }, 300),
    // 获取排考方式
    async getInquireRule() {
      await inquireRule(this.examId).then((res) => {
        if (res.success) {
          this.rulesArrangeModel = res.data.arrangeModel
        }
        console.log('this.rulesArrangeModel', this.rulesArrangeModel)
      })
    },
    //获取市级考试数据信息
    getCityExamInfo() {
      getCityPlatformExamInfoApi(this.urlParam.companyCityExamId).then(
        (res) => {
          if (res.success) {
            this.gmtDataGetting = res.data.gmtDataGetting
            this.gmtDataConfirm = res.data.gmtDataConfirm
            this.examDataStatus = res.data.examDataStatus
          } else {
            this.msgWarning(res.msg)
          }
        }
      )
    },
    //终止并删除考试数据
    terminationAndDeleteCityExamData() {
      terminationAndDeleteCityExamDataApi(
        this.examId,
        this.urlParam.companyCityExamId
      ).then((res) => {
        if (res.success) {
          this.$router.replace(
            `/schoolManufacturer/examInfo?examId&companyCityExamId=${this.urlParam.companyCityExamId}`
          )
          this.$router.go(0)
        } else {
          this.msgWarning(res.msg)
        }
      })
    },
  },
  filters: {
    dayList(val) {
      return dayjs(val).format('YYYY-MM-DD HH:mm')
    },
  },
}
</script>

<style lang="scss" scoped>
#content {
  padding-bottom: 20px;
}
.margin-box {
  margin-top: 20px;
  padding: 0 10px 20px 10px;
  border: 1px solid rgb(223, 230, 236);
}
.header {
  padding-left: 25px;
  min-height: 50px;
  line-height: 50px;
  border-bottom: 1px solid rgb(223, 230, 236);
}
.header > span:first-child {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid rgb(223, 230, 236);
  text-align: center;
  line-height: 30px;
}
.header > span:last-child {
  margin-left: 15px;
  font-size: 20px;
}

#base-info-box,
#exam-time-box,
#exam-model-box,
#exam-subject-box {
  padding: 2px 5px;
  text-align: center;
  background-color: rgb(215, 215, 215);
}

.badge-des {
  background-color: rgb(223, 230, 236);
}

.badge-info {
  background-color: white;
}

.el-row {
  height: 35px;
  line-height: 35px;
}

#exam-subject-box ::v-deep .el-row {
  height: 60px;
  line-height: 60px;
}

#examinee-info-box {
  margin-top: 20px;
}

#examinee-info-box ::v-deep .el-form-item__content .el-input {
  width: 160px;
}

#marking-rule-box {
  margin-top: 20px;
}

#marking-rule-box > div {
  margin-bottom: 15px;
}

#marking-rule-box > div:last-child {
  margin-bottom: 0px;
}

#marking-rule-box > div > span:first-child {
  display: inline-block;
  width: 100px;
  text-align: right;
}

#marking-rule-box > div:first-child > span {
  margin-left: 30px;
}

#marking-rule-box > div:first-child > span:first-child {
  margin-left: 0px;
}

#marking-rule-box > div > span:last-child {
  margin-left: 30px;
}

// 下拉卡片
.cardBox {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  border: 1px solid #dfe6ec;
  margin-bottom: 20px;
  .cardTit {
    box-sizing: border-box;
    font-size: 20px;
    font-weight: bold;
    border-bottom: 1px #dfe6ec solid;
    margin-bottom: 20px;
    vertical-align: middle;
    i {
      cursor: pointer;
    }
    span {
      box-sizing: border-box;
      display: inline-block;
      margin: 0 8px;
      width: 36px;
      height: 36px;
      font-size: 16px;
      line-height: 36px;
      text-align: center;
      border-radius: 50%;
      border: 1px solid #dfe6ec;
      font-weight: normal;
      margin-bottom: 8px;
    }
  }
  .cardContent {
    overflow: hidden;
  }
}
</style>
